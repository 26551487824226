import { Image } from "rebass";
import ListIcon from "../assets/images/list-icon.png";
import ListIconGreen from "../assets/images/list-icon-2.png";

const FancyListItem = ({ children, icon, ...otherProps }: any) => (
  <li
    {...otherProps}
    style={{
      fontWeight: 700,
      display: "flex",
      alignItems: "flex-start",
      margin: "16px 0 0",
      padding: "0",
    }}
  >
    <Image
      src={icon === "purple" ? ListIcon : ListIconGreen}
      alt="list icon"
      width="24px"
      height="24px"
      mr={3}
      mb={2}
      style={{
        margin: "4px 16px 4px 0",
        borderRadius: "50%",
        backgroundColor: "#fff",
        boxShadow: "0 4px 8px 0 rgba(143, 174, 227, .29)",
      }}
    />
    {children}
  </li>
);

export default FancyListItem;
