// extracted by mini-css-extract-plugin
export var GlobalModuleHeroFooterHbGMF = "Home-module--Global-module--hero--footer--HbGMF--QNvQc";
export var approach_image = "Home-module--approach_image--GdRI+";
export var approach_image_container = "Home-module--approach_image_container--3bvqr";
export var built__grid = "Home-module--built__grid--iI+IA";
export var built__gridSection = "Home-module--built__grid--section--Ml8ke";
export var cta = "Home-module--cta--TL8A4";
export var ctaButton = "Home-module--cta-button--9jMQ2";
export var ctaHeading = "Home-module--cta-heading--oG3OK";
export var ddwsv__grid = "Home-module--ddwsv__grid--Vejb1";
export var ddwsv__gridSection = "Home-module--ddwsv__grid--section--HKaO0";
export var etsp__grid = "Home-module--etsp__grid--C3Ftq";
export var etsp__gridSection = "Home-module--etsp__grid--section--R09Rz";
export var flowDiagram__gridSection = "Home-module--flowDiagram__gridSection--8uqdv";
export var gawp__grid = "Home-module--gawp__grid--fLNFm";
export var gawp__gridSection = "Home-module--gawp__grid--section--4MG-A";
export var heroCta = "Home-module--hero--cta--PZRgd";
export var heroCtaStripe1 = "Home-module--hero--cta-stripe-1--Gae59";
export var heroCtaStripe2 = "Home-module--hero--cta-stripe-2--mwpJy";
export var heroCtaStripe3 = "Home-module--hero--cta-stripe-3--oVOds";
export var heroCtaStripe4 = "Home-module--hero--cta-stripe-4--GoeBm";
export var heroCtaStripe5 = "Home-module--hero--cta-stripe-5--2pQgo";
export var heroCtaStripes = "Home-module--hero--cta-stripes--xx9ir";
export var heroCtaStripesInner = "Home-module--hero--cta-stripes-inner--TktsI";
export var heroHome = "Home-module--hero--home--pn0Yg";
export var heroHomeStripe = "Home-module--hero--home-stripe--QhfFC";
export var heroHomeStripe1 = "Home-module--hero--home-stripe-1--mB135";
export var heroHomeStripe2 = "Home-module--hero--home-stripe-2--Jcle0";
export var heroHomeStripe3 = "Home-module--hero--home-stripe-3--0ZSdT";
export var heroHomeStripe4 = "Home-module--hero--home-stripe-4--7H6Wk";
export var heroHomeStripe5 = "Home-module--hero--home-stripe-5--IWWOK";
export var heroHomeStripe6 = "Home-module--hero--home-stripe-6--b7hOi";
export var heroHomeStripes = "Home-module--hero--home-stripes--X5tDe";
export var heroHomeStripesInner = "Home-module--hero--home-stripes-inner--bPm-O";
export var heroHomeTestimonialStripe1 = "Home-module--hero--home-testimonial-stripe-1--0Blv1";
export var heroHomeTestimonialStripes = "Home-module--hero--home-testimonial-stripes--81aj3";
export var heroHomeTestimonialStripesInner = "Home-module--hero--home-testimonial-stripes-inner--xPPqx";
export var heroWhySkodel = "Home-module--hero--why-skodel--oX5Fo";
export var heroWhySkodelStripe1 = "Home-module--hero--why-skodel-stripe-1--tLrgx";
export var heroWhySkodelStripe2 = "Home-module--hero--why-skodel-stripe-2--GnS7S";
export var heroWhySkodelStripe3 = "Home-module--hero--why-skodel-stripe-3--7qYYc";
export var heroWhySkodelStripe4 = "Home-module--hero--why-skodel-stripe-4--RKrq8";
export var heroWhySkodelStripes = "Home-module--hero--why-skodel-stripes--2127Q";
export var heroWhySkodelStripesInner = "Home-module--hero--why-skodel-stripes-inner--K-VF-";
export var hero__homeImage = "Home-module--hero__home--image--MlfTH";
export var hero__homeText = "Home-module--hero__home--text--lkLpB";
export var homeLargeScaleContent = "Home-module--home-large-scale-content--e4Chc";
export var homeLargeScaleGroup = "Home-module--home-large-scale-group--zAoKh";
export var homeLargeScaleImage = "Home-module--home-large-scale-image--BwB1m";
export var homeLargeScaleList = "Home-module--home-large-scale-list--ix1zI";
export var homeLargeScaleText = "Home-module--home-large-scale-text--nABj6";
export var homeLargeScaleWrapper = "Home-module--home-large-scale-wrapper--7Yk29";
export var homeTestimonialCarouselNext = "Home-module--home-testimonial-carousel-next--FqxA-";
export var homeTestimonialCarouselPrev = "Home-module--home-testimonial-carousel-prev--269bH";
export var home__builtImage = "Home-module--home__built--image--OiXJL";
export var home__digDeeperWithStudentVoiceImage = "Home-module--home__dig-deeper-with-student-voice-image--9AIfd";
export var home__easilyTrackStudentPositivityImage = "Home-module--home__easily-track-student-positivity-image--ECboj";
export var home__flowDiagram_image = "Home-module--home__flow-diagram_image--7mEQO";
export var home__getAWellbeingPulseImage = "Home-module--home__get-a-wellbeing-pulse-image--JcTwC";
export var home__headerButton = "Home-module--home__header--button--D0v3N";
export var home__heroButton = "Home-module--home__hero--button--NXw1x";
export var home__learnerCarousel = "Home-module--home__learner-carousel--OeCyd";
export var home__learnerImage = "Home-module--home__learner-image--wdjj0";
export var home__learnerImageBrooklynAnnunciation = "Home-module--home__learner-image-brooklyn-annunciation--c1RhW";
export var home__learnerImageChessington = "Home-module--home__learner-image-chessington--Ayzyq";
export var home__learnerImageEmmanuelCollegeWarrnambool = "Home-module--home__learner-image-emmanuel-college-warrnambool--5K7HQ";
export var home__learnerImageGals = "Home-module--home__learner-image-gals--wkgeD";
export var home__learnerImageGreenmeadows = "Home-module--home__learner-image-greenmeadows--fncg+";
export var home__learnerImageHolyEucharist = "Home-module--home__learner-image-holy-eucharist--xvJzq";
export var home__learnerImageHume = "Home-module--home__learner-image-hume--7pDAO";
export var home__learnerImageHunterValleyGrammar = "Home-module--home__learner-image-hunter-valley-grammar--ISHYm";
export var home__learnerImageLauncestonChurchGrammar = "Home-module--home__learner-image-launceston-church-grammar--CeNlt";
export var home__learnerImageManurera = "Home-module--home__learner-image-manurera--SLxzR";
export var home__learnerImageNewRoadsSchool = "Home-module--home__learner-image-new-roads-school--b1ZJM";
export var home__learnerImageRandwickPark = "Home-module--home__learner-image-randwick-park--ViXka";
export var home__learnerImageSchool = "Home-module--home__learner-image-school--ioOZY";
export var home__learnerImageStMargaretsAndBerwick = "Home-module--home__learner-image-st-margarets-and-berwick--GbeZi";
export var home__learnerImageTheScotsCollege = "Home-module--home__learner-image-the-scots-college--Xky1v";
export var home__learnerImageTrinityGrammarSchool = "Home-module--home__learner-image-trinity-grammar-school--OJqXr";
export var home__learnerItem = "Home-module--home__learner-item--kqKgx";
export var home__learnerName = "Home-module--home__learner-name--i2Jr9";
export var home__logoBgg = "Home-module--home__logo-bgg--t81MO";
export var home__patternBig = "Home-module--home__pattern-big--vYRY7";
export var home__patternMedium = "Home-module--home__pattern-medium--pNZZ8";
export var home__patternSmall = "Home-module--home__pattern-small--6Z1cZ";
export var home__patternWhite1 = "Home-module--home__pattern-white-1--S-NlT";
export var home__patternWhite2 = "Home-module--home__pattern-white-2--nI2xC";
export var home__patternWhite3 = "Home-module--home__pattern-white-3--ryA1J";
export var home__pioneerStripe1 = "Home-module--home__pioneer--stripe-1--2CftZ";
export var home__pioneerStripesInner = "Home-module--home__pioneer--stripes-inner--UovC1";
export var home__researchButton = "Home-module--home__research--button--rs+T5";
export var home__researchImage = "Home-module--home__research--image--svu6z";
export var home__testimonialsCarousel = "Home-module--home__testimonials-carousel--Z11Xh";
export var home__testimonialsImageDevin = "Home-module--home__testimonials-image-devin--Cjen5";
export var home__testimonialsImageFaleula = "Home-module--home__testimonials-image-faleula--kC+bD";
export var home__testimonialsImageKevin = "Home-module--home__testimonials-image-kevin--A68hT";
export var home__testimonialsImageStephen = "Home-module--home__testimonials-image-stephen--dj8vw";
export var home__testimonialsItem = "Home-module--home__testimonials-item--uO9HL";
export var home__testimonialsLinkDevin = "Home-module--home__testimonials-link-devin--bVoLz";
export var home__testimonialsLinkFaleula = "Home-module--home__testimonials-link-faleula--WRZIF";
export var home__testimonialsLinkKevin = "Home-module--home__testimonials-link-kevin--+sTv3";
export var home__testimonialsLinkStephen = "Home-module--home__testimonials-link-stephen--IoUr5";
export var home__testimonialsMessage = "Home-module--home__testimonials-message--zhUTZ";
export var home__testimonialsName = "Home-module--home__testimonials-name--gIPI8";
export var home__testimonialsOccupation = "Home-module--home__testimonials-occupation--DjPZE";
export var home__testimonialsSection = "Home-module--home__testimonials--section--osRax";
export var home__timelyTailoredWellbeingSupportImage = "Home-module--home__timely-tailored-wellbeing-support-image--GcBF0";
export var home__understandKeyDriversImage = "Home-module--home__understand-key-drivers-image--znFNY";
export var home__wellbeingEmbedVideo = "Home-module--home__wellbeing-embed-video--05Esd";
export var home__wellbeingEmbedVideoBg = "Home-module--home__wellbeing-embed-video-bg--41090";
export var home__wellbeingImage = "Home-module--home__wellbeing--image--LDlsp";
export var icwlp__grid = "Home-module--icwlp__grid--e4-sy";
export var icwlp__gridSection = "Home-module--icwlp__grid--section--qr0rP";
export var newsroomHeaderCard = "Home-module--newsroom-header-card--axXe0";
export var newsroomHeaderColor = "Home-module--newsroom-header-color--m6s6x";
export var newsroomHeaderColorBlue = "Home-module--newsroom-header-color-blue--XXR7W";
export var newsroomHeaderColorYou = "Home-module--newsroom-header-color-you--+iz0P";
export var newsroomHeaderImage = "Home-module--newsroom-header-image--TrbpE";
export var newsroomHeaderText = "Home-module--newsroom-header-text--oG5O9";
export var newsroomHeaderTextMessage = "Home-module--newsroom-header-text-message--To0ML";
export var newsroomHeaderTextName = "Home-module--newsroom-header-text-name--WHcCA";
export var newsroomHeaderTextTitle = "Home-module--newsroom-header-text-title--Xz6YQ";
export var quote_container = "Home-module--quote_container--b7c5X";
export var research__grid = "Home-module--research__grid--n6jh9";
export var research__gridSection = "Home-module--research__grid--section--wnK+N";
export var solutions_image_container = "Home-module--solutions_image_container--9Ldm3";
export var stats_bg = "Home-module--stats_bg---fves";
export var stats_bg_container = "Home-module--stats_bg_container--Kqh9e";
export var stats_container = "Home-module--stats_container--+eWhp";
export var ttws__gridSection = "Home-module--ttws__grid--section--H93Ub";
export var ukd__grid = "Home-module--ukd__grid--b+gZZ";
export var ukd__gridSection = "Home-module--ukd__grid--section--dmiqD";
export var wellbeing__grid = "Home-module--wellbeing__grid--84NkX";
export var wellbeing__text = "Home-module--wellbeing__text--PlKFa";