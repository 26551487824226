import styled from "styled-components";
import { Link } from "gatsby";
import { Button } from "rebass";

export const BrandButton = styled(Link)({
  background: "#ffffff",
  borderRadius: "4px",
  color: "#1565E5",
  fontWeight: 700,
  padding: "12px 24px",
  letterSpacing: "0px",
  textDecoration: "none",
  display: "inline-block",
});

export const BrandButtonA = styled.a({
  background: "#ffffff",
  borderRadius: "4px",
  color: "#1565E5",
  fontWeight: 700,
  padding: "12px 24px",
  letterSpacing: "0px",
  textDecoration: "none",
  display: "inline-block",
});

export const BrandButtonA_black = styled.a({
  background: "#192A44",
  boxShadow: "0px 20px 40px rgba(25, 42, 68, 0.2)",
  borderRadius: "40px",
  color: "#FFFFFF",
  lineHeight: "20px",
  fontWeight: 700,
  padding: "18px 30px",
  letterSpacing: "0px",
  textDecoration: "none",
  display: "inline-block",
});

export const BrandButtonInput = styled.input({
  background: "#ffffff",
  borderRadius: "4px",
  color: "#1565E5",
  fontWeight: 700,
  padding: "12px 24px",
  letterSpacing: "0px",
  textDecoration: "none",
  display: "inline-block",
});

export const BrandButtonInverse = styled(Button)({
  background: "#FFFFFF",
  color: "rgb(93, 170, 242)",
  textTransform: "uppercase",
  fontWeight: 700,
  letterSpacing: "2px",
});
