import React, { Component } from "react";
import { Heading, Box, Text, Flex } from "rebass";
// import { Link } from "react-router-dom";
import WidthMaxer from "./WidthMaxer";

import * as globalStyles from "../styles/Global.module.css";
import { Link } from "gatsby";

import LogoDark from "../assets/images/logo.png";
import footerFacebook from "../assets/images/footer-facebook.png";
import footerTwitter from "../assets/images/footer-twitter.png";
import footerLinkedin from "../assets/images/footer-linkedin.png";

const currentDate = new Date();

export const HeroGridCard = ({ style, ...otherProps }: any) => (
  <WidthMaxer
    {...otherProps}
    style={{
      // minHeight: "80vh",
      ...style,
    }}
  />
);

class Footer extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <HeroGridCard
        className={`${globalStyles.heroFooter}`}
        color="white"
        justifyContent="center"
        alignItems="center"
        style={{
          maxWidth: "unset",
          zIndex: 0,
          // overflow: "hidden"
        }}
      >
        <Box
          className={`${globalStyles.heroFooterStripes}`}
          // style={{ filter: "blur(150px)" }}
        >
          {/* <Box className={`${globalStyles.heroFooterStripesInner}`}>
            <Box className={`${globalStyles.heroFooterStrip1}`}/>
            <Box className={`${globalStyles.heroFooterStripe2}`} />
            <Box className={`${globalStyles.heroFooterStripe3}`} />
          </Box> */}
        </Box>
        <Box
          style={{
            position: "relative",
            zIndex: 1,
            width: "100%",
            maxWidth: "1164px",
            margin: "0 auto",
            padding: "50px 32px 0",
          }}
        >
          <Box className={`${globalStyles.footerGrid}`}>
            {/* Logo */}
            <Box className={`${globalStyles.heroFooterLeft}`}>
              <Link to="/" style={{ display: "block" }}>
                <img
                  src={LogoDark}
                  className="navbar__logo"
                  alt="Skodel logo"
                />
              </Link>
              <a
                href="mailto:info@skodel.com"
                // to="mailto:info@skodel.com"
                style={{
                  display: "block",
                  fontSize: "17px",
                  lineHeight: "27px",
                  textDecoration: "none",
                  color: "#fff",
                }}
              >
                info@skodel.com
              </a>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: "24px",
                }}
              >
                <a
                  href="/tou.pdf"
                  target="_blank"
                  rel="noreferrer"
                  download
                  style={{
                    display: "block",
                    fontSize: "17px",
                    lineHeight: "27px",
                    textDecoration: "none",
                    color: "#fff",
                  }}
                >
                  Terms of Use
                </a>
                <Text style={{ margin: "0 5px" }}>|</Text>
                <a
                  href="/privacy-policy.pdf"
                  target="_blank"
                  rel="noreferrer"
                  download
                  style={{
                    display: "block",
                    fontSize: "17px",
                    lineHeight: "27px",
                    textDecoration: "none",
                    color: "#fff",
                  }}
                >
                  Privacy Policy
                </a>
              </Box>
              <Text
                style={{
                  margin: "5px 0 0",
                  fontSize: "17px",
                  lineHeight: "27px",
                  color: "#fff",
                }}
              >
                &copy; Skodel {currentDate.getFullYear()}
              </Text>
            </Box>
            <Box className={globalStyles.heroFooterMiddle}>
              {/* Pages */}
              <Box className={`${globalStyles.heroFooterMenu}`}>
                <Heading
                  style={{
                    margin: "0 0 20px",
                    fontSize: "17px",
                    lineHeight: "27px",
                  }}
                >
                  Pages
                </Heading>
                <Link
                  to="/"
                  style={{
                    display: "block",
                    marginTop: "5px",
                    fontSize: "13px",
                    lineHeight: "26px",
                    textDecoration: "none",
                    color: "#fff",
                  }}
                >
                  Home
                </Link>
                <Link
                  to="/our-approach"
                  style={{
                    display: "block",
                    marginTop: "5px",
                    fontSize: "13px",
                    lineHeight: "26px",
                    textDecoration: "none",
                    color: "#fff",
                  }}
                >
                  Our Approach
                </Link>
                <Link
                  to="/features"
                  style={{
                    display: "block",
                    marginTop: "5px",
                    fontSize: "13px",
                    lineHeight: "26px",
                    textDecoration: "none",
                    color: "#fff",
                  }}
                >
                  Features
                </Link>
              </Box>
              <Box className={`${globalStyles.heroFooterMenu}`}>
                <Heading
                  style={{
                    margin: "0 0 20px",
                    fontSize: "17px",
                    lineHeight: "27px",
                  }}
                >
                  Company
                </Heading>
                <Link
                  to="/resources"
                  style={{
                    display: "block",
                    marginTop: "5px",
                    fontSize: "13px",
                    lineHeight: "26px",
                    textDecoration: "none",
                    color: "#fff",
                  }}
                >
                  Resources
                </Link>
                <Link
                  to="/about-us"
                  style={{
                    display: "block",
                    marginTop: "5px",
                    fontSize: "13px",
                    lineHeight: "26px",
                    textDecoration: "none",
                    color: "#fff",
                  }}
                >
                  About Us
                </Link>
                <Link
                  to="/contact-us"
                  style={{
                    display: "block",
                    marginTop: "5px",
                    fontSize: "13px",
                    lineHeight: "26px",
                    textDecoration: "none",
                    color: "#fff",
                  }}
                >
                  Contact
                </Link>
              </Box>
            </Box>
            {/* Follow social media */}
            <Box className={`${globalStyles.heroFooterMenu}`}>
              <Heading
                style={{
                  margin: "0 0 20px",
                  fontSize: "17px",
                  lineHeight: "27px",
                }}
              >
                Follow
              </Heading>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <a
                  href="https://www.facebook.com/skodeltechnology/"
                  target="_blank"
                  rel="noreferrer"
                  title="Skodel Facebook"
                  style={{
                    marginRight: "15px",
                  }}
                >
                  <img
                    src={footerFacebook}
                    alt="Skodel Facebook"
                    style={{ display: "block", maxWidth: "16px" }}
                  />
                </a>
                <a
                  href="https://twitter.com/Skodelwellbeing"
                  target="_blank"
                  rel="noreferrer"
                  title="Skodel Twitter"
                  style={{
                    marginRight: "15px",
                  }}
                >
                  <img
                    src={footerTwitter}
                    alt="Skodel Twitter"
                    style={{ display: "block", maxWidth: "16px" }}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/skodel"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={footerLinkedin}
                    alt="Skodel LinkedIn"
                    style={{ display: "block", maxWidth: "16px" }}
                  />
                </a>
              </Box>
            </Box>
          </Box>
        </Box>
      </HeroGridCard>
    );
  }
}

export default Footer;
