import * as React from "react";
import { Heading, Text, Image, Flex, Box } from "rebass";
import Page from "../components/Page";
import IndexLayout from "../layouts";
import { Helmet } from "react-helmet";
import Modal from "../components/Modal";
import ReactPlayer from "react-player";
import * as styles from "./Checkin.module.css";
import SiteFooter from "../components/SiteFooter";

import * as globalStyles from "../styles/Global.module.css";
import * as homeStyles from "./Home.module.css";
import ArrowPlayIcon from "../assets/images/md-arrow-dropright-circle.svg";
import IpadScreenshot from "../assets/images/checkin-Illustration.png";
import HomeGetAWellbeingPulse from "../assets/images/home-get-a-wellbeing-pulse.png";
import HomeGetAWellbeingPulseWebp from "../assets/images/home-get-a-wellbeing-pulse.webp";
import HomeEasilyTrackStudentPositivity from "../assets/images/individualised.png";
import HomeUnderstandKeyDrivers from "../assets/images/home-understand-key-drivers.png";
import HeroLayoutEmpty from "../components/HeroLayoutEmpty";
import HeroLayout from "../components/HeroLayout";
import FancyListItem from "../components/FancyListItem";

import HeroGridCard from "../components/HeroGridCard";
import HeroGrid from "../components/HeroGrid";
import { BrandButtonA } from "../components/BrandButton";
import listPurple from "../assets/images/list-purple.png";
import largeScale from "../assets/images/large-scale.png";
import skodelStudent from "../assets/images/skodel-student.png";
import listRed from "../assets/images/list-red.png";
import listOrange from "../assets/images/list-orange.png";

import WidthMaxer from "../components/WidthMaxer";
import { useEffect } from "react";
import { navigate } from "gatsby";

const CheckInPage = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  useEffect(() => {
    navigate("/education");
  }, []);

  return (
    <IndexLayout>
      <Page>
        <HeroGrid
          style={{ position: "relative", overflow: "hidden", rowGap: "0" }}
        >
          <Helmet>
            <title>Skodel - Check-In</title>
            <meta property="og:title" content="Skodel - Check-In" />
            <meta
              property="og:description"
              content="Skodel's wellbeing check-in helps students and teachers label their emotions accurately, and provides support to each person based on how they are feeling."
            />
          </Helmet>
          <HeroGridCard
            className={`${styles.heroMission}`}
            color="white"
            style={{ maxWidth: "unset" }}
          >
            <Box className={`${styles.heroMissionStripes} brand-stripes`}>
              <Box className={`${styles.heroMissionStripesInner}`}>
                <Box className={`${styles.heroMissionStripe1}`} />
                <Box className={`${styles.heroMissionStripe2}`} />
                <Box className={`${styles.heroMissionStripe3}`} />
                <Box className={`${styles.heroMissionStripe4}`} />
                <Box className={`${styles.heroMissionStripe5}`} />
                <Box className={`${styles.heroMissionStripe6}`} />
                <Box className={`${styles.heroMissionStripe7}`} />
                <Box className={`${styles.heroMissionStripe8}`} />
                <Box className={`${styles.heroMissionStripe9}`} />
                <Box className={`${styles.heroMissionStripe10}`} />
                <Box className={`${styles.heroMissionStripe11}`} />
              </Box>
            </Box>
            <WidthMaxer>
              <HeroLayout
                mt={"200px"}
                style={{ zIndex: 1, margin: "140px 0 0" }}
                copyProps={{
                  style: {
                    position: "relative",
                    zIndex: 1,
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  },
                }}
                imageProps={{
                  style: {
                    position: "relative",
                    zIndex: 0,
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  },
                }}
                copy={
                  <Box
                    className={styles.hero__homeText}
                    style={{ marginTop: "92px" }}
                  >
                    <Heading
                      fontWeight={700}
                      mb={3}
                      style={{
                        margin: "0",
                        fontSize: "42px",
                        lineHeight: "57px",
                      }}
                    >
                      Give Every Student a Voice
                    </Heading>
                    <Text
                      my={3}
                      style={{
                        margin: "20px 0 36px",
                        fontSize: "17px",
                        lineHeight: "30px",
                      }}
                    >
                      Skodel Check-In helps everyone reflect on, understand and
                      use all their feelings as a force for good
                    </Text>
                    <Flex
                      py={5}
                      className={styles.home__heroButton}
                      flexWrap="wrap"
                      style={{ padding: "0" }}
                    >
                      <a
                        className={`${globalStyles.btn}`}
                        href="/signup/"
                        style={{
                          cursor: "pointer",
                          background: "rgb(255, 255, 255)",
                          borderRadius: "4px",
                          color: "#EC6CD1",
                          fontWeight: 700,
                          padding: "12px 24px",
                          letterSpacing: "0px",
                          textDecoration: "none",
                          display: "inline-block",
                        }}
                      >
                        Try for Free
                      </a>
                      <BrandButtonA
                        className={`${globalStyles.btn}`}
                        onClick={() => setIsModalOpen(true)}
                        title="Click here to watch how it works"
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          background: "rgba(255, 255, 255, 0.1)",
                          color: "#FFFFFF",
                        }}
                      >
                        <Image
                          src={ArrowPlayIcon}
                          alt=""
                          style={{
                            display: "block",
                            width: "22px",
                            marginBottom: "2px",
                            marginRight: "5px",
                          }}
                        />
                        <span style={{ display: "block" }}>
                          Watch How it Works
                        </span>
                      </BrandButtonA>
                    </Flex>
                  </Box>
                }
                image={
                  <picture>
                    <source type="image/png" srcSet={IpadScreenshot} />
                    <img
                      className={styles.hero__homeImage}
                      src={IpadScreenshot}
                      alt=""
                    />
                  </picture>
                }
              />
            </WidthMaxer>
          </HeroGridCard>
          <HeroGridCard style={{ position: "relative" }}>
            <WidthMaxer style={{ width: "100%" }}>
              <HeroLayoutEmpty
                className={styles.hero__homeImagey}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Flex
                  color="rgb(91, 157, 233)"
                  mx="auto"
                  bg="rgb(237, 245, 252)"
                  style={{
                    borderRadius: "20px",
                    marginTop: "0",
                    marginBottom: "17px",
                    padding: "8px 20px",
                  }}
                >
                  <Heading fontSize={2}>
                    Pioneering the wellbeing check-in
                  </Heading>
                </Flex>
                <Box className={styles.home__wellbeingEmbedVideo}>
                  <Box className={styles.home__wellbeingEmbedVideoBg} />
                  <ReactPlayer
                    config={{
                      youtube: {
                        embedOptions: {
                          host: "https://www.youtube-nocookie.com",
                        },
                      },
                    }}
                    url="https://www.youtube-nocookie.com/watch?v=AKMhp6UHDh8"
                    width="100%"
                    height="100%"
                    style={{
                      display: "block",
                      position: "absolute",
                      top: "0",
                      left: "0",
                    }}
                  />
                </Box>
                <Heading
                  fontWeight={600}
                  textAlign="center"
                  my={3}
                  mx="auto"
                  style={{
                    width: "100%",
                    maxWidth: "750px",
                    marginTop: "0",
                    marginBottom: "0",
                    fontSize: "36px",
                    lineHeight: "50px",
                    display: "none",
                  }}
                >
                  Helping schools build safe and connected learning environments
                </Heading>
                <Text
                  mx="auto"
                  mt={5}
                  textAlign="center"
                  style={{
                    maxWidth: "900px",
                    marginTop: "24px",
                    fontSize: "17px",
                    lineHeight: "32px",
                    color: "#737B8B",
                    display: "none",
                  }}
                >
                  Improving wellbeing enables learning and growth to happen
                  best. Yet making it a focus at your school can be time
                  consuming and complex. Skodel is a refreshingly simple and
                  light touch approach to improving wellbeing outcomes.
                </Text>
              </HeroLayoutEmpty>
            </WidthMaxer>
          </HeroGridCard>
          <Modal
            className="modal"
            show={isModalOpen}
            close={() => setIsModalOpen(false)}
          >
            <ReactPlayer
              url="https://www.youtube.com/watch?v=AKMhp6UHDh8"
              playing={isModalOpen}
              width="calc(100% - 30px)"
              height="calc(100% - 30px)"
              style={{
                display: "block",
                position: "absolute",
                top: "15px",
                left: "15px",
              }}
            />
          </Modal>
          <HeroGridCard
            className={styles.gawp__gridSection}
            style={{ zIndex: 1 }}
          >
            <HeroLayout
              className={styles.gawp__grid}
              imageOnLeft
              copyProps={{ style: { position: "relative", zIndex: 1 } }}
              imageProps={{
                style: { position: "relative" },
                alignItems: "flex-start",
              }}
              image={
                <div className={styles.home__getAWellbeingPulseImage}>
                  <div className={styles.home__patternBig} />
                  <div className={styles.home__patternMedium} />
                  <div className={styles.home__patternSmall} />
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={HomeGetAWellbeingPulseWebp}
                    />
                    <source type="image/png" srcSet={HomeGetAWellbeingPulse} />
                    <img
                      className={styles.home__getAWellbeingPulseImage}
                      src={HomeGetAWellbeingPulseWebp}
                      alt=""
                    />
                  </picture>
                </div>
              }
              copy={
                <React.Fragment>
                  <Heading
                    fontWeight={600}
                    style={{
                      maxWidth: "100%",
                      margin: "0",
                      fontSize: "36px",
                      lineHeight: "50px",
                    }}
                  >
                    Simple, quick and impactful check-ins
                  </Heading>
                  <Text
                    my={3}
                    style={{
                      maxWidth: "600px",
                      margin: "20px 0 0",
                      fontSize: "17px",
                      lineHeight: "32px",
                      color: "#737B8B",
                    }}
                  >
                    Using our feelings positively is like discovering a new
                    secret power.
                  </Text>
                  <ul
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "0",
                      marginTop: "24px",
                      padding: "0",
                      listStyle: "none",
                    }}
                  >
                    <FancyListItem icon="purple">
                      <Text
                        style={{
                          fontSize: "17px",
                          fontWeight: 600,
                          lineHeight: "32px",
                          letterSpacing: "0",
                        }}
                      >
                        Schedule one off or recurring check-ins to the whole
                        school or select groups, set the pace to go as
                        frequently or infrequently as you like
                      </Text>
                    </FancyListItem>
                    <FancyListItem icon="purple">
                      <Text
                        style={{
                          fontSize: "17px",
                          fontWeight: 600,
                          lineHeight: "32px",
                          letterSpacing: "0",
                        }}
                      >
                        Customise check-ins to assess, get feedback and align
                        them to the areas that are important to your school or
                        frameworks you have adopted
                      </Text>
                    </FancyListItem>
                    <FancyListItem icon="purple">
                      <Text
                        style={{
                          fontSize: "17px",
                          fontWeight: 600,
                          lineHeight: "32px",
                          letterSpacing: "0",
                        }}
                      >
                        Build self awareness and emotional regulation by giving
                        students a safe and familiar way to express how they are
                        going.
                      </Text>
                    </FancyListItem>
                  </ul>
                </React.Fragment>
              }
            />
          </HeroGridCard>
          <HeroGridCard
            className={styles.etsp__gridSection}
            style={{ zIndex: 1 }}
          >
            <HeroLayout
              className={styles.etsp__grid}
              copyProps={{
                style: { position: "relative", zIndex: 1, paddingTop: "0" },
              }}
              imageProps={{
                style: { position: "relative" },
                alignItems: "flex-start",
              }}
              copy={
                <React.Fragment>
                  <Heading
                    fontWeight={600}
                    style={{
                      margin: "0",
                      fontSize: "36px",
                      lineHeight: "50px",
                    }}
                  >
                    A user friendly but data rich dashboard
                  </Heading>
                  <Text
                    my={3}
                    style={{
                      maxWidth: "600px",
                      margin: "20px 0 0",
                      fontSize: "17px",
                      lineHeight: "32px",
                      color: "#737B8B",
                    }}
                  >
                    Skodel won’t overwhelm you with data, you’ll get key
                    insights and tailored support from leading psychologists.
                  </Text>
                  <ul
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "0",
                      marginTop: "24px",
                      padding: "0",
                      listStyle: "none",
                    }}
                  >
                    <FancyListItem icon="green">
                      <Text
                        style={{
                          fontSize: "17px",
                          fontWeight: 600,
                          lineHeight: "32px",
                          letterSpacing: "0",
                        }}
                      >
                        Simple filters and sophisticated alerts help you
                        identify need to know wellbeing and safety concerns
                      </Text>
                    </FancyListItem>
                    <FancyListItem icon="green">
                      <Text
                        style={{
                          fontSize: "17px",
                          fontWeight: 600,
                          lineHeight: "32px",
                          letterSpacing: "0",
                        }}
                      >
                        Easily segment students into any groups from your SIS or
                        within Skodel and conduct a cohort analysis in seconds
                      </Text>
                    </FancyListItem>
                    <FancyListItem icon="green">
                      <Text
                        style={{
                          fontSize: "17px",
                          fontWeight: 600,
                          lineHeight: "32px",
                          letterSpacing: "0",
                        }}
                      >
                        Account permissions ensure you can effectively manage
                        who sees what data and make it work at all levels within
                        your school
                      </Text>
                    </FancyListItem>
                  </ul>
                </React.Fragment>
              }
              image={
                <div
                  className={styles.home__easilyTrackStudentPositivityImage}
                  style={{
                    margin: "0 0 0 50px",
                  }}
                >
                  <div className={styles.home__patternBig} />
                  <div className={styles.home__patternMedium} />
                  <div className={styles.home__patternSmall} />
                  <picture>
                    <source
                      type="image/png"
                      srcSet={HomeEasilyTrackStudentPositivity}
                    />
                    <img
                      src={HomeEasilyTrackStudentPositivity}
                      alt=""
                      style={{
                        width: "537px",
                        height: "auto",
                        position: "relative",
                        left: "100px",
                        top: "50px",
                      }}
                    />
                  </picture>
                </div>
              }
            />
          </HeroGridCard>
          <HeroGridCard
            className={styles.ukd__gridSection}
            style={{ zIndex: 1 }}
          >
            <HeroLayout
              className={styles.ukd__grid}
              imageOnLeft
              copyProps={{ style: { position: "relative", zIndex: 1 } }}
              imageProps={{
                style: { position: "relative" },
                alignItems: "flex-start",
              }}
              image={
                <div className={styles.home__understandKeyDriversImage}>
                  <div className={styles.home__patternBig} />
                  <div className={styles.home__patternMedium} />
                  <Image src={HomeUnderstandKeyDrivers} alt="" />
                </div>
              }
              copy={
                <React.Fragment>
                  <Heading
                    fontWeight={600}
                    style={{
                      maxWidth: "100%",
                      margin: "0",
                      fontSize: "36px",
                      lineHeight: "50px",
                    }}
                  >
                    Tools for students to increase their own wellbeing
                  </Heading>
                  <Text
                    my={3}
                    style={{
                      maxWidth: "600px",
                      margin: "20px 0 0",
                      fontSize: "17px",
                      lineHeight: "32px",
                      color: "#737B8B",
                    }}
                  >
                    Track your feelings over time and get immediate support in
                    understanding the feeling your are experiencing and how best
                    to manage it.
                  </Text>
                  <ul
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "0",
                      marginTop: "24px",
                      padding: "0",
                      listStyle: "none",
                    }}
                  >
                    <FancyListItem icon="purple">
                      <Text
                        style={{
                          fontSize: "17px",
                          fontWeight: 600,
                          lineHeight: "32px",
                          letterSpacing: "0",
                        }}
                      >
                        Engaging insights to help students build a better
                        understanding of themselves and their wellbeing
                      </Text>
                    </FancyListItem>
                    <FancyListItem icon="purple">
                      <Text
                        style={{
                          fontSize: "17px",
                          fontWeight: 600,
                          lineHeight: "32px",
                          letterSpacing: "0",
                        }}
                      >
                        Evidence-informed resources from leading psychologists
                        plus the option to add your own
                      </Text>
                    </FancyListItem>
                    <FancyListItem icon="purple">
                      <Text
                        style={{
                          fontSize: "17px",
                          fontWeight: 600,
                          lineHeight: "32px",
                          letterSpacing: "0",
                        }}
                      >
                        Access to 24/7 support lines to connect students to
                        support when they need it
                      </Text>
                    </FancyListItem>
                  </ul>
                </React.Fragment>
              }
            />
          </HeroGridCard>
          <HeroGridCard
            style={{
              position: "relative",
              zIndex: 1,
              margin: "143px auto 0",
              display: "none",
            }}
          >
            <HeroLayoutEmpty
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box className={styles.homeLargeScaleContent}>
                <Heading
                  fontWeight={600}
                  textAlign="center"
                  style={{
                    width: "100%",
                    maxWidth: "882px",
                    margin: "0 auto",
                    fontSize: "36px",
                    lineHeight: "50px",
                  }}
                >
                  Managing student wellbeing
                </Heading>
                <Text
                  fontSize={2}
                  textAlign="center"
                  style={{
                    maxWidth: "900px",
                    margin: "24px auto 75px",
                    fontSize: "17px",
                    lineHeight: "30px",
                    color: "#737B8B",
                  }}
                >
                  With wellbeing fluctuating rapidly and so few students
                  communicating, it is now recognized that wellbeing management
                  benefits hugely from a regular check-in that unlocks student
                  voice and provides real time data.
                </Text>
                <Box className={styles.homeLargeScaleWrapper}>
                  <Box className={styles.homeLargeScaleImage}>
                    <Image src={largeScale} alt="" />
                  </Box>
                  <Box className={styles.homeLargeScaleText}>
                    <Box
                      className={styles.homeLargeScaleGroup}
                      style={{ backgroundColor: "rgba(255, 163, 187, 0.1)" }}
                    >
                      <Heading
                        style={{
                          fontWeight: 600,
                          fontSize: "17px",
                          lineHeight: "30px",
                          color: "#232D42",
                        }}
                      >
                        Teacher Observations
                      </Heading>
                      <Box className={styles.homeLargeScaleList}>
                        <Image src={listRed} alt="" />
                        Teachers log any wellbeing issues they might observe and
                        can compare to the trends shown in check-in data
                      </Box>
                    </Box>
                    <Box
                      className={styles.homeLargeScaleGroup}
                      style={{ backgroundColor: "rgba(203, 113, 255, 0.1)" }}
                    >
                      <Heading
                        style={{
                          fontWeight: 600,
                          fontSize: "17px",
                          lineHeight: "30px",
                          color: "#232D42",
                        }}
                      >
                        Large Scale Survey
                      </Heading>
                      <Box className={styles.homeLargeScaleList}>
                        <Image src={listPurple} alt="" />
                        Monitor findings from large scale surveys on an ongoing
                        basis through real time check-ins
                      </Box>
                    </Box>
                    <Box
                      className={styles.homeLargeScaleGroup}
                      style={{ backgroundColor: "rgba(255, 170, 111, 0.1)" }}
                    >
                      <Heading
                        style={{
                          fontWeight: 600,
                          fontSize: "17px",
                          lineHeight: "30px",
                          color: "#232D42",
                        }}
                      >
                        Regular Check-Ins (Skodel Check-In)
                      </Heading>
                      <Box className={styles.homeLargeScaleList}>
                        <Image src={listOrange} alt="" />
                        Regular check-ins can help on minor and major wellbeing
                        issues
                      </Box>
                      <Box className={styles.homeLargeScaleList}>
                        <Image src={listOrange} alt="" />
                        Fast action is possible if the data is real time
                      </Box>
                      <Box className={styles.homeLargeScaleList}>
                        <Image src={listOrange} alt="" />
                        Real time data aids teacher effectiveness and
                        relationships with students
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </HeroLayoutEmpty>
          </HeroGridCard>
          <HeroGridCard
            className={styles.heroWhySkodel}
            style={{ maxWidth: "unset", marginBottom: "200px" }}
          >
            <Box className={styles.heroWhySkodelStripes}>
              <Box className={styles.heroWhySkodelStripesInner}>
                <Box className={styles.heroWhySkodelStripe1} />
                <Box className={styles.heroWhySkodelStripe2} />
                <Box className={styles.heroWhySkodelStripe3} />
                <Box className={styles.heroWhySkodelStripe4} />
              </Box>
            </Box>
            <HeroLayoutEmpty
              style={{
                zIndex: 1,
                paddingRight: "32px",
                paddingLeft: "32px",
              }}
              px={2}
            >
              <WidthMaxer>
                <Flex
                  alignItems="center"
                  className={styles.homeLargeScaleImagetColumn}
                >
                  <Box className={styles.homeLargeScaleImaget}>
                    <Image src={skodelStudent} alt="" />
                  </Box>
                  <Box className={styles.homeLargeScaleImagetText}>
                    <Heading
                      fontSize={5}
                      color="white"
                      textAlign="left"
                      marginBottom="16px"
                    >
                      Respond simply and meaningfully
                    </Heading>
                    <Text
                      style={{
                        fontWeight: 400,
                        fontSize: "17px",
                        lineHeight: "30px",
                        color: "#fff",
                      }}
                    >
                      As students learn more about their emotions, teachers can
                      use ‘Skodel Reactions’ to respond in a simple and
                      meaningful way. Celebrate the moments of joy and support
                      students when they need it most. Skodel Reactions makes it
                      easy for teachers to amplify the impact of checking-in.
                    </Text>
                  </Box>
                </Flex>
              </WidthMaxer>
            </HeroLayoutEmpty>
          </HeroGridCard>
          <HeroGridCard
            className={styles.heroWhySkodelt}
            style={{ maxWidth: "unset", display: "none" }}
          >
            <HeroLayoutEmpty
              style={{
                zIndex: 1,
                paddingRight: "32px",
                paddingLeft: "32px",
              }}
              px={2}
            >
              <WidthMaxer>
                <Flex alignItems="center">
                  <Box
                    margin="0 auto"
                    textAlign="center"
                    className={styles.heroWhySkodeltext}
                  >
                    <Heading
                      fontSize={2}
                      color="#131415"
                      marginBottom="70px"
                      textAlign="center"
                    >
                      Understand what matters to you with ‘Customised Check-Ins’
                    </Heading>
                    <Text
                      style={{
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "26px",
                        color: "#7B7B7B",
                      }}
                    >
                      Skodel’s ‘Customised Check-Ins’ enable you to take the
                      simple and engaging experience of Skodel’s Check-In model
                      and apply it to the domains that matter most to you. Add,
                      edit and remove questions so you can start collecting data
                      in line with your strategic vision. Skodel has built out a
                      range of Specialist Check-Ins in partnership with leading
                      psychologists, teachers and school leaders to further
                      support you with this.
                    </Text>
                  </Box>
                </Flex>
              </WidthMaxer>
            </HeroLayoutEmpty>
          </HeroGridCard>
          <HeroGridCard
            className={`${homeStyles.heroCta} ${globalStyles.heroCta}`}
            color="white"
            justifyContent="center"
            alignItems="center"
            style={{ maxWidth: "unset" }}
          >
            <Box className={`${homeStyles.heroCtaStripes}`}>
              <Box className={`${homeStyles.heroCtaStripesInner}`}>
                <Box className={`${homeStyles.heroCtaStripe1}`} />
                <Box className={`${homeStyles.heroCtaStripe2}`} />
                <Box className={`${homeStyles.heroCtaStripe3}`} />
                <Box className={`${homeStyles.heroCtaStripe4}`} />
                <Box className={`${homeStyles.heroCtaStripe5}`} />
              </Box>
            </Box>
            <HeroLayoutEmpty
              style={{
                zIndex: 9001,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "calc(100% - 60px)",
                maxWidth: "900px",
                margin: "0 30px",
                padding: "40px",
                backgroundColor: "#fff",
                borderRadius: "15px",
                boxShadow: "0 22px 60px 0 rgba(24, 50, 92, .1)",
              }}
            >
              <Flex className={`${homeStyles.cta}`}>
                <Heading
                  className={`${homeStyles.ctaHeading}`}
                  style={{
                    fontSize: "32px",
                    lineHeight: "44px",
                    color: "#232D42",
                  }}
                >
                  See ‘Skodel Check-In’ in Action
                </Heading>
                <Flex className={`${homeStyles.ctaButton}`}>
                  <a
                    className={`${globalStyles.btn}`}
                    href="/signup/"
                    style={{
                      padding: "12px 30px",
                      backgroundColor: "#AA63FF",
                      color: "#FFFFFF",
                      boxShadow: "0 8px 26px 0 rgba(170, 99, 255, 0.30)",
                      textDecoration: "none",
                      borderRadius: "4px",
                      fontWeight: 700,
                    }}
                  >
                    Try for free
                  </a>
                  <BrandButtonA
                    className={`${globalStyles.btn}`}
                    onClick={() => setIsModalOpen(true)}
                    style={{
                      cursor: "pointer",
                      padding: "12px 30px",
                      backgroundColor: "rgba(170, 99, 255, .2)",
                      color: "#AA63FF",
                    }}
                  >
                    View Demo
                  </BrandButtonA>
                </Flex>
              </Flex>
            </HeroLayoutEmpty>
          </HeroGridCard>
          <SiteFooter />
        </HeroGrid>
      </Page>
    </IndexLayout>
  );
};

export default CheckInPage;
